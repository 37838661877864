<template>
  <div class="container">
    <div class="new-ticket">
      <div class="fa fa-angle-left" id="back" @click="goBack"></div>
      <span class="name">{{ $t("addTicket.newTicket") }}</span>
    </div>
    <div class="content">
      <form autocomplete="off">

        <div class="input-field col s12" :key="localizationChangedKey">
          <label for="manufacturer" class="active">{{ $t("addTicket.manufacturers") }}</label>
          <input id="manufacturer" type="text"
                 v-model="selectedManufacturerName"
                 list="selectManufacturers"
                 @input="getItemsByManufacturerName">
          <datalist id="selectManufacturers"
                    @change="getItemsByManufacturerName">
            <option v-for="manufacturer in manufacturers" :key="manufacturer.id"
                    v-bind:value="manufacturer.name">
              {{ manufacturer.name }}
            </option>
          </datalist>
        </div>

        <div class="input-field col s12">
          <label for="system" class="active">{{ $t("addTicket.items") }}</label>
          <input id="system" type="text"
                 v-model="selectedItemSystem"
                 list="itemsByManufacturer"
                 @input="onSystemNameChanged">
          <datalist id="itemsByManufacturer" @change="onSystemNameChanged">
            <option v-for="item in selectedManufacturerItems"
                    :key="item.id"
                    v-bind:value="item.system">
              {{ item.system }}
            </option>
          </datalist>
        </div>

        <div class="input-field col s12" v-if="ticketType.name !== 'DEBUG'">
          <label for="mountType" class="active">{{ $t("addTicket.mountType") }}</label>
          <input id="mountType" type="text"
                 v-model="content.mountType"
                 list="selectMountTypes">
          <datalist id="selectMountTypes">
            <option v-for="mountType in this.mountTypes"
                    :key="mountType"
                    v-bind:value="mountType">
              {{ mountType }}
            </option>
          </datalist>
        </div>

        <div class="input-field col" v-if="ticketType.name !== 'DEBUG'">
          <label for="width" class="active">
            <span v-if="!this.isWidthValid" class="invalid_value">{{ $t("addTicket.enterWidth") }}</span>
            <span v-else>{{ $t("addTicket.width") }}</span>
          </label>
          <input id="width" type="text" v-model.number="content.width">
        </div>

        <div class="input-field col" v-if="ticketType.name !== 'DEBUG'">
          <label for="depth" class="active">
            <span v-if="!this.isDepthValid" class="invalid_value">{{ $t("addTicket.enterDepth") }}</span>
            <span v-else>{{ $t("addTicket.depth") }}</span>
          </label>
          <input id="depth" type="text" v-model.number="content.depth">
        </div>

        <div class="input-field col" v-if="ticketType.name !== 'DEBUG'">
          <label for="thickness" class="active">
            <span v-if="!this.isThicknessValid" class="invalid_value">{{ $t("addTicket.enterThickness") }}</span>
            <span v-else>{{ $t("addTicket.thickness") }}</span>
          </label>
          <input id="thickness" type="text" v-model.number="content.thickness">
        </div>

        <div class="input-field col" v-if="ticketType.name !== 'DEBUG'">
          <label for="horizontalStep" class="active">
            <span v-if="!this.isL1Valid" class="invalid_value">{{ $t("addTicket.enterHorizontalStep") }}</span>
            <span v-else>{{ $t("addTicket.horizontalStep") }}</span>
          </label>
          <input id="horizontalStep" type="text" v-model.number="content.horizontalStep">
        </div>

        <div class="input-field col" v-if="ticketType.name !== 'DEBUG'">
          <label for="verticalStep" class="active">
            <span v-if="!this.isL2Valid" class="invalid_value">{{ $t("addTicket.enterVerticalStep") }}</span>
            <span v-else>{{ $t("addTicket.verticalStep") }}</span>
          </label>
          <input id="verticalStep" type="text" v-model.number="content.verticalStep">
        </div>

        <div class="input-field col">
          <label for="notes" class="active">{{ $t("addTicket.notes") }} </label>
          <textarea id="notes" type="text" v-model="content.notes"/>
        </div>

        <div class="input-field col s12">
          <select id="selectTicketType" v-model="ticketType" @change="onTicketTypeChanged">
            <option v-for="ticketType in this.ticketTypes" :key="ticketType.id" v-bind:value="ticketType">
              {{ $t("ticketType." + ticketType.name) }}
            </option>
          </select>
          <label for="selectTicketType" class="active" >{{ $t("addTicket.ticketType") }}</label>
        </div>

        <div class="input-field col s12">
          <select id="selectAssignedUser" v-model="assigned">
            <option v-for="admin in admins" :key="admin.id" v-bind:value="admin">
              {{ admin.username }}
            </option>
          </select>
          <label class="active" for="selectAssignedUser">{{ $t("addTicket.assigned") }}</label>
        </div>

        <p></p>

        <div class="controllers">
          <button @click="createTicket" type="button" id="create" class="btn waves-effect waves-light">
            {{ $t("addTicket.create") }}<i class="fa fa-plus right"></i>
          </button>
        </div>
      </form>
    </div>

    <div class="image">
      <div class="image spin" v-if="upload">
        <RingLoader color="red"/>
      </div>
      <div v-else>
        <div v-if="photoQuantity === 0" @click="onSelectFile">
          <img src="../../../public/no-photo.jpg" v-bind:alt="$t('addTicket.noImageAlt')">
        </div>
        <div v-else-if="photoQuantity > 0">
          <div style="display: flex;">
            <div class="fa fa-angle-left" style="float: left; font-size: 150%;" @click="nextImage(-1)"></div>
            <button class="download-photo-button btn waves-effect waves-light">
              <a id="downloadBtnText" :href="currentImage" :key="localizationChangedKey" download>
                {{ $t("addTicket.downloadImage", [selectedPhotoNumber + 1, photoQuantity]) }}
              </a>
            </button>
            <div class="fa fa-angle-right" @click="nextImage(1)" style="float: right; font-size: 150%"></div>
          </div>

          <img v-show="ticketType.name !== 'DEBUG'" :src="currentImage" v-bind:alt="$t('addTicket.imageAlt')">
          <video v-show="ticketType.name === 'DEBUG'" :src="currentVideo" muted controls></video>
        </div>

        <input type="file" :accept="fileFormat" @change="onFileSelected" style="display: none" ref="fileInput">

        <div v-if="photoQuantity > 0">
          <div id="delete-img" @click="onDeleteImage">
            <div id="deleteImg" class="fa fa-trash-o">
              <span v-if="ticketType.name === 'DEBUG'" id="deleteImg"> {{ $t("addTicket.deleteVideo") }}</span>
              <span v-else id="deleteImg"> {{ $t("addTicket.deleteImage") }}</span>
            </div>
          </div>
        </div>

        <div v-if="photoQuantity < maxImages">
          <div id="upload-img" @click="onSelectFile">
            <div id="fa-upload" class="fa fa-upload">
              <span v-if="ticketType.name === 'DEBUG'" id="upNewImg"> {{ $t("addTicket.uploadVideo") }}</span>
              <span v-else id="upNewImg"> {{ $t("addTicket.uploadImage") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";
import ui from "../utils/ui";
import ManufacturersBoard from "../manufacturer/ManufacturersBoard";
import {GlobalLocalizationEventEmitter} from "@/i18n";
import {RingLoader} from "@saeris/vue-spinners";
import $ from "jquery";

export default {
  components: {RingLoader},
  data() {
    let data = {
      ticket: {},
      ticketTypes: [],
      manufacturers: [],
      selectedManufacturerItems: [],
      selectedManufacturerName: undefined,
      selectedItemSystem: undefined,
      ticketType: {},
      assigned: undefined,
      content: {},
      admins: {},
      mountTypes: [],
      currentManufacturer: undefined,
      currentItem: null,
      localizationChangedKey: 0,
      isWidthValid: true,
      isDepthValid: true,
      isThicknessValid: true,
      isL2Valid: true,
      isL1Valid: true,
      upload: false,
      attachmentList: [],
      fileFormat: 'image/*',
      maxImages: 5,
      photoQuantity: 0,
      selectedFile: null,
      selectedPhotoNumber: 0,
      currentVideo: undefined,
      currentImage: undefined
    }

    this.getTicketTypes();
    this.getAdminUsers();
    this.getMountTypes();

    return data;
  },

  created() {
    GlobalLocalizationEventEmitter.$on("localizationChanged", () => {
      this.localizationChangedKey += 1;
    })
  },

  beforeDestroy() {
    GlobalLocalizationEventEmitter.$off("localizationChanged", this);
  },

  mounted() {
    this.getManufacturers();
  },

  methods: {
    createTicket() {
      if (!this.checkFields()) {
        return
      }

      this.fmtFields();
      this.getItemsByManufacturerName().then(() => {
        if (this.currentItem != null) {
          this.content.id = this.currentItem.id;
        } else {
          this.content.id = undefined;
        }
        this.content.manufacturer = this.selectedManufacturerName;
        this.content.system = this.selectedItemSystem;

        let attachmentList = [];
        if (this.ticketType.name === "DEBUG" && !ui.methods.isEmptyString(this.currentVideo)) {
           attachmentList = [this.currentVideo];
        } else {
          attachmentList = this.attachmentList;
        }

        const ticket = {
          content: this.contentToString(),
          ticketType: this.ticketType.name,
          ticketStatus: "NEW",
          attachmentList: attachmentList,
          assigned: this.assigned != null ? this.assigned.username : undefined,
          assignedId: this.assigned != null ? this.assigned.id : undefined
        }
        const headers = Link.methods.getHeaders();
        axios.post(
            Link.methods.getTicketCreateUrl(),
            ticket,
            {headers}
        ).then(response => {
          this.content = response.data.content
          this.createToast(this.$t("addTicket.createdToast"), "success");
          setTimeout(() => this.goBack(), 200);
        }).catch(() => {
          this.createToast(this.$t("addTicket.notCreatedToast"), "error");
        });
      })
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    getTicketTypes() {
      let headers = Link.methods.getHeaders();
      axios.get(
        Link.methods.getTicketTypesUrl(),
        {headers}
      ).then(res => {
        this.ticketTypes = res.data.filter((el) => el.name !== "NEW USER" && el.name !== "NONE");
        const ticketType = this.ticketTypes.find((el) => el.name === "NEW REGAL");
        this.ticketType = ticketType != null ? ticketType : this.ticketTypes[0];
      }).catch(() => {
        this.createToast(this.$t("addTicket.ticketTypesNotFound"), "error")
      })
    },

    contentToString() {
      return JSON.stringify(this.content);
    },

    getAdminUsers() {
      let headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getAllUsersUrl(),
          {headers}
      ).then(res => {
        if (res != null && res.data != null) {
          this.admins = res.data.filter((it) => it.role === 'ADMIN');
        }
      }).catch(() => {
        this.createToast(this.$t("addTicketRule.usersNotFound"), "error")
      });
    },

    goBack() {
      return this.$router.back()
    },

    getManufacturers() {
      ManufacturersBoard.methods.getManufacturers().then(data => this.manufacturers = data);
    },

    getItemsByManufacturerName() {
      const mName = (this.selectedManufacturerName ? this.selectedManufacturerName.toUpperCase() : undefined);
      this.currentManufacturer = this.manufacturers.find( (el) => (el.name ? el.name.toUpperCase() : '') === mName);
      return new Promise((resolve) => {
        if (this.currentManufacturer != null) {
          this.selectedManufacturerName = this.currentManufacturer.name;
          const manufacturerId = this.currentManufacturer.id;
          const headers = Link.methods.getHeaders();
          axios.get(Link.methods.getItemsByManufacturer(
                  manufacturerId),
              {headers}
          ).then(res => {
            this.selectedManufacturerItems = res.data;
            this.onSystemNameChanged();
            resolve();
          }).catch(reason => {
            console.error(reason)
            resolve();
          })
        } else {
          this.selectedManufacturerItems = [];
          this.onSystemNameChanged();
          resolve();
        }
      });
    },

    onSystemNameChanged() {
      const systemName = (this.selectedItemSystem ? this.selectedItemSystem.toUpperCase() : undefined);
      if (this.currentManufacturer != null && systemName != null) {
        this.currentItem = this.selectedManufacturerItems.find((it) =>
            (it.system ? it.system.toUpperCase() : '') === systemName
        );
        if (this.currentItem) {
          this.selectedItemSystem = this.currentItem.system;
        }
      } else {
        this.currentItem = undefined;
      }

      const ticketName = this.ticketType.name;
      if (ticketName === 'UPDATE' || ticketName === 'NEW REGAL') {
        const newTicketName = (this.currentItem != null ? 'UPDATE' : 'NEW REGAL');
        this.ticketType = this.ticketTypes.find((el) => el.name === newTicketName);
      }
    },

    getMountTypes() {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getMountTypes(), {headers}
      ).then(res => {
        this.mountTypes = res.data;
      })
    },

    fmtFields() {
      this.content.horizontalStep = ui.methods.fmt(this.content.horizontalStep);
      this.content.verticalStep = ui.methods.fmt(this.content.verticalStep);
      this.content.width = ui.methods.fmt(this.content.width);
      this.content.depth = ui.methods.fmt(this.content.depth);
      this.content.thickness = ui.methods.fmt(this.content.thickness);
    },

    checkFields() {
      this.isWidthValid = (
          !ui.methods.isEmptyNumber(this.content.width)
          || this.content.width == null
          || !isNaN(this.content.width)
      );
      this.isDepthValid = (
          !ui.methods.isEmptyNumber(this.content.depth)
          || this.content.depth == null
          || !isNaN(this.content.depth)
      );
      this.isThicknessValid = (
          !ui.methods.isEmptyNumber(this.content.thickness)
          || this.content.thickness == null
          || !isNaN(this.content.thickness)
      );
      this.isL1Valid = (
          !ui.methods.isEmptyNumber(this.content.horizontalStep)
          || this.content.horizontalStep == null
          || !isNaN(this.content.horizontalStep)
      );
      this.isL2Valid = (
          !ui.methods.isEmptyNumber(this.content.verticalStep)
          || this.content.verticalStep == null
          || !isNaN(this.content.verticalStep)
      );

      return this.isWidthValid
          && this.isL2Valid
          && this.isL1Valid
          && this.isDepthValid
          && this.isThicknessValid;
    },

    onDeleteImage() {
      if (this.ticketType.name === 'DEBUG') {
           this.currentVideo = undefined;
           this.photoQuantity = 0;
      } else {
        if (
            this.photoQuantity > 0
            && this.selectedPhotoNumber >= 0
            && this.selectedPhotoNumber < this.photoQuantity
        ) {
          this.attachmentList.splice(this.selectedPhotoNumber, 1);
          this.photoQuantity = this.attachmentList.length;
          if (this.selectedPhotoNumber > (this.photoQuantity - 1)) {
            this.selectedPhotoNumber = this.photoQuantity > 0 ? this.photoQuantity - 1 : 0;
          }
          this.updateImage();
        }
      }
    },

    onSelectFile() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },

    onFileSelected() {
      $('#upload').css('display', 'block');
      let selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append('file', selectedFile)

      const headers = Link.methods.getHeaders();
      if (selectedFile != null) {
        this.upload = true;
        return axios.post(
            Link.methods.getFilePhotoUploadUrl(),
            fd,
            {headers}
        ).then(res => {
          if (this.ticketType.name === 'DEBUG') {
            this.currentVideo = res.data;
            this.photoQuantity = 1;
            this.selectedPhotoNumber = 0;
          } else {
            this.attachmentList.push(res.data);
            this.photoQuantity = this.attachmentList.length;
            this.selectedPhotoNumber = this.photoQuantity - 1;
            this.updateImage();
          }
          this.localizationChangedKey +=1;
          this.upload = false;
        }).catch((e) => {
          this.createToast(this.$t("addTicket.imageNotLoaded", [e.message]), "error")
          this.upload = false;
        });
      }
    },

    nextImage(num) {
      if (this.photoQuantity > 1) {
        this.selectedPhotoNumber += num;
        this.updateImage();
      }
    },

    updateImage() {
      if (this.photoQuantity === 0) {
        this.currentImage = undefined;
        this.selectedPhotoNumber = 0;
        return;
      }

      if (this.selectedPhotoNumber >= this.photoQuantity) {
        this.selectedPhotoNumber = 0;
      } else if (this.selectedPhotoNumber < 0) {
        this.selectedPhotoNumber = this.photoQuantity - 1;
      }

      this.currentImage = this.attachmentList[this.selectedPhotoNumber];
    },

    onTicketTypeChanged() {
      if (this.ticketType.name === 'DEBUG') {
        this.maxImages = 1;
        this.fileFormat = 'video/*';
        this.photoQuantity =  (ui.methods.isEmptyString(this.currentVideo)) ? 0 : 1;
      } else {
        this.maxImages = 5;
        this.fileFormat = 'image/*';
        this.photoQuantity = this.attachmentList.length;
        this.currentImage = this.attachmentList[0];
      }
      this.selectedPhotoNumber = 0;
      this.localizationChangedKey += 1;
    }
  }
}
</script>


<style scoped lang="scss">

label.active {
  margin-top: -2%;
}

input {
  margin-bottom: 10px;
}

#delete-img, #upload-img {
  display: flex;
  text-align: center;
}

#upload-img:hover, #delete-img:hover {
  cursor: pointer;

  #upNewImg {
    color: red;
  }

  #fa-upload {
    color: red;
  }
}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import "public/styles/vars.scss";
@import "public/styles/singleTicket";
@import '../../../public/styles/hints.scss';

i.right {
  margin-left: 6px;
}

.btn {
  font-size: 13px;
  line-height: initial;
  padding: 0 4px 0 6px;
}

.input-field > label > span.invalid_value {
  color: red;
  font-size: 13px;
}

#notes {
  height:auto;
  width: 100%;
  margin:0 0 8px 0;
  padding: 0 0 0 0.75rem;
  resize: none;
  box-sizing: border-box;
  color:black; border:none;
  font-family: BloggerSans,sans-serif !important;
}
</style>